import { useState, useEffect } from "react";
import "./App.css";
import {
  RadixDappToolkit,
  RadixNetwork,
  DataRequestBuilder,
  FungibleResourcesVaultCollection,
} from "@radixdlt/radix-dapp-toolkit";
import InputUser from "./InputUser";

let wallet_address: string;
const rdt = RadixDappToolkit({
  dAppDefinitionAddress:
    "account_rdx12ytvmefsvcmz9q9cfqf8ysuxyq6f2ntlpsss6vev0zc62runzhh0ee",
  networkId: RadixNetwork.Mainnet,
  applicationName: "Airdrop",
  applicationVersion: "1.0.0",
});

rdt.walletApi.setRequestData(
  DataRequestBuilder.accounts().exactly(1),
  DataRequestBuilder.personaData().emailAddresses()
);

rdt.buttonApi.setTheme("radix-blue");
rdt.buttonApi.setMode("dark");

function App() {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  rdt.walletApi.walletData$.subscribe((walletData) => {
    walletData.accounts.length > 0
      ? setIsConnected(true)
      : setIsConnected(false);
  });

  const [walletVaults, setWalletVaults] =
    useState<FungibleResourcesVaultCollection | null>(null);
  rdt.walletApi.provideConnectResponseCallback(async (response) => {
    if (response.isErr()) {
      window.alert("connection rejected");
      return;
    }
    console.log(walletVaults);
  });

  useEffect(() => {
    async function setWalletData() {
      if (isConnected) {
        wallet_address = rdt.walletApi.getWalletData().accounts[0].address;
        let _wallet_vaults =
          await rdt.gatewayApi.state.getEntityDetailsVaultAggregated(
            wallet_address
          );
        setWalletVaults(_wallet_vaults.fungible_resources);
      }
    }
    setWalletData();
  }, [isConnected]);

  return (
    <div className="App">
      <div className="App-header">
        <InputUser state={isConnected} rdt={rdt}/>
      </div>
    </div>
  );
}

export default App;
