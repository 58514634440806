import "./InputUser.css";
import { ChangeEvent, useState} from "react";
import Papa from "papaparse";
import TableResource from "./TableResource";
import { RadixDappToolkit } from "@radixdlt/radix-dapp-toolkit";

type isWalletConnected = {
  state: boolean;
  rdt: RadixDappToolkit
};

type AddressData =  {
  address: string,
  amounts: string,
}[]
interface DataToSend  {
  resource_address: string | "",
  coin_distribution_type: string,
  coin_amount: string,
  addresses: AddressData | null
  token_address: string,
}

function InputUser({ state, rdt}: isWalletConnected) {
  const [isSerialized, setIsSerialized] = useState<boolean>(false);
  const [addressesData, setAddressesData] = useState<AddressData>([]);
  const [file, setFile] = useState<File | null>(null);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [isCustomAmount, setIsCustomAmount] = useState<boolean>(false);
  const [values, setValues] = useState<DataToSend>({
    resource_address: "",
    coin_distribution_type: "spread",
    coin_amount: "0",
    token_address: "",
    addresses: null,
  });
  const [isSended, setIsSended] = useState<boolean>(false);
  
  const setToggle = (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    setIsCustomAmount(!isCustomAmount);
  };

  const verifyFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.value.slice(
        event.target.value.length - 4,
        event.target.value.length
      ) !== ".csv"
    ) {
      window.alert("Please select .csv file");
      setFile(null);
      return;
    }
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const setData = (result: Papa.ParseResult<Object>) => {
    let data: AddressData = [];
    result.data.forEach((address: any) => {
    if (address.address.trim() !== '') {
        data.push({
            address: address.address as string,
            amounts: address.amounts as string
        });
      }
    });

    setAddressesData(data);
    setValues({
      ...values,
      addresses: data,
    });
  };

  const confirm = async () => {
    setIsPending(false); 
    setIsConfirmed(true);
  };

  
  const serializeCSV = () => {
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: setData,
      });

      setIsSerialized(true);
    }
  };

    const handleCoinDistribution = (event: ChangeEvent<HTMLSelectElement>) => {
      setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const sendData = async () => {
    let manifest : string = "";
    if (!isCustomAmount) {
      let coin_to_take : number = 0;
      for (let i=0; i<addressesData.length; i++) {
        coin_to_take = coin_to_take + Number(addressesData[i].amounts)
      }
      manifest = manifest.concat(`
        CALL_METHOD
          Address("${rdt.walletApi.getWalletData().accounts[0].address}")
          "withdraw"
          Address("${values.token_address}")
          Decimal("${coin_to_take}")
        ;`.trim())
      let a = values.addresses?.length ? values.addresses?.length : 0;
      for (let i=0; i<a; i++) {
        let data = addressesData[i];
        manifest = manifest.concat(`
        TAKE_FROM_WORKTOP
          Address("${values.token_address}")
          Decimal("${data.amounts}")
          Bucket("bucket${i}")
        ;
        CALL_METHOD
          Address("${data.address}")
          "try_deposit_or_abort"
          Bucket("bucket${i}")
          Enum<0u8>()
        ;`).trim()
      }
      manifest = manifest.concat(`
        CALL_METHOD
          Address("${rdt.walletApi.getWalletData().accounts[0].address}")
          "deposit_batch"
          Expression("ENTIRE_WORKTOP")
          ;`).trim()
        } else {
      let coin_to_take = values.coin_distribution_type === "spread" ? Number(values.coin_amount) : Number(values.coin_amount) * Number(values.addresses?.length)
      let coin_to_send = values.coin_distribution_type === "spread" ? (Number(values.coin_amount) / Number(values.addresses?.length)).toFixed(3) : Number(values.coin_amount)
          
      manifest = manifest.concat(`
        CALL_METHOD
          Address("${rdt.walletApi.getWalletData().accounts[0].address}")
          "withdraw"
          Address("${values.token_address}")
          Decimal("${coin_to_take}")
        ;`.trim())
      let a = values.addresses?.length ? values.addresses?.length : 0;
      for (let i=0; i<a; i++) {
        let data = addressesData[i];
        manifest = manifest.concat(`
        TAKE_FROM_WORKTOP
          Address("${values.token_address}")
          Decimal("${coin_to_send}")
          Bucket("bucket${i}")
        ;
        CALL_METHOD
          Address("${data.address}")
          "try_deposit_or_abort"
          Bucket("bucket${i}")
          Enum<0u8>()
        ;`).trim()
      }
      manifest = manifest.concat(`
        CALL_METHOD
          Address("${rdt.walletApi.getWalletData().accounts[0].address}")
          "deposit_batch"
          Expression("ENTIRE_WORKTOP")
        ;`).trim()
    }

    console.log(manifest)
    let tx = rdt.walletApi.sendTransaction({transactionManifest:manifest}).then(data => {
      console.log(data._unsafeUnwrap().transactionIntentHash)
      return data._unsafeUnwrap().transactionIntentHash;
    });
    setIsSended(true);
    console.log(tx)
  };
  
  const setValue = (event: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    console.log(values)
  };

  return (
    <div>
      <form onSubmit={sendData}>
        <div className="container">
          <label className="csv-input" htmlFor="fileInput">
            {file ? file.name : "Click here to select CSV file"}
          </label>
          <input
            type="file"
            id="fileInput"
            name="fileInput"
            className="file-name-input"
            onChange={verifyFile}
          />
          {file && (
            <button
              type="button"
              disabled={isSerialized}
              onClick={serializeCSV}
              className="upload-button"
            >
              {isSerialized ? "Uploaded" : "Upload"}
            </button>
          )}
          <div className="flex-container">
            <div className="flex-container-ver">
            <div className="input_ver">
              <label htmlFor="mode">Mode : </label>
              <select id="mode" onChange={setToggle}>
                <option value="predefined-amount">Pre-Defined Amount</option>
                <option value="custom-amount">Custom Amount</option>
              </select>
            </div>
            <div className="input_ver">
              <label htmlFor="coinDistribution">Coin Distribution : </label>
              <select disabled={!isCustomAmount} id="coinDistribution" name="coin_distribution_type" onChange={handleCoinDistribution}>
                <option value="spread">spread</option>
                <option value="even">even</option>
              </select>
            </div>
            <div>
              <label htmlFor="coin_amount">Amounts : </label>
              <input
                disabled={!isCustomAmount}
                type="number"
                id="coin_amount"
                name="coin_amount"
                min={0}
                step={1}
                onChange={setValue}
                defaultValue={0}
                />
            </div>
            <div>
              <label htmlFor="token_address">Token Address : </label>
              <input
                type="text"
                id="token_address"
                name="token_address"
                onChange={setValue}
                />
            </div>
            </div>
          </div>
          {isConfirmed ? (
            <div>
              <button
                type="button"
                onClick={sendData}
                disabled={
                  isSended === false && state === true && isSerialized === true ? false : true
                }
                className="proceed-button"
              >
                Send Transactions
              </button>{" "}
              <p>
                {state === true && isSerialized === true
                  ? ""
                  : "Please Connect your wallet and upload csv file first!"}
              </p>
            </div>
          ) : (
            <div>
              <button
                type="button"
                onClick={confirm}
                disabled={
                  isPending === false && state === true && isSerialized === true ? false : true
                }
                className="proceed-button"
              >
                Procced
              </button>{" "}
              <p>
                {state === true && isSerialized === true
                  ? ""
                  : "Please Connect your wallet and upload csv file first!"}
              </p>
            </div>
          )}
        </div>
      </form>
      <TableResource
        isConnected={state === true && isSerialized === true ? false : true}
        items={addressesData}
      />
    </div>
  );
}
export default InputUser;
