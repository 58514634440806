import "./TableResource.css";

interface AddressData {
  address:string,
  amounts:string,
}
type Walet_state = {
  isConnected: boolean;
  items: AddressData[];
};
function TableResource({ isConnected, items }: Walet_state) {
  return (
    <div className="table-container">
      {isConnected || (
        <table>
          <thead>
            <tr>
              <th>List of Addresses</th>
              <th>Amount of Token</th>
            </tr>
          </thead>
          <tbody>
            {items.map((row: AddressData, index) => (
              <tr key={index}>
                <td>{row.address as string}</td>
                <td>{(row.amounts as string) || "No amounts info yet"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default TableResource;
